import Senha from '@/views/area/Senha'
import FormView from '@/views/FormView'
import AreaView from '@/views/AreaView'
import Boleto from '@/views/area/Boleto'
import Titulos from '@/views/area/Titulos'
import InitalView from '@/views/InitalView'
import Download from '@/views/area/Download'
import Impugnacao from '@/views/area/Impugnacao'
import Restituicao from '@/views/area/Restituicao'
import ImpugnacaoTitulos from '@/views/area/ImpugnacaoTitulos'
import ImpugnacaoInscricao from '@/views/area/ImpugnacaoInscricao'

export default [
  // Home
  { path: '/', component: InitalView, name: 'home' },

  // Form inscrição
  { path: '/formulario', component: FormView, name: 'form' },

  // Area do candidato
  {
    path: '/area-candidato',
    name: 'area-candidato',
    component: AreaView,
    children: [
      { path: 'senha', component: Senha, name: 'area-senha' },
      { path: 'boleto', component: Boleto, name: 'area-boleto' },
      { path: 'formulario', component: Download, name: 'area-form' },
      { path: 'impugnacao', component: Impugnacao, name: 'area-impugnacao' },
      { path: 'envio-de-titulos', component: Titulos, name: 'area-titulos' },
      { path: 'restituicao', component: Restituicao, name: 'area-restituicao' },
      { path: 'impugnacao-titulos', component: ImpugnacaoTitulos, name: 'area-impugnacao-titulos' },
      { path: 'impugnacao-inscricao', component: ImpugnacaoInscricao, name: 'area-impugnacao-inscricao' }
    ]
  },

  { path: '*', component: InitalView, name: 'home' }
]
