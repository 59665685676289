<template>
  <section class="container text-center pb-5 bg-f2">
    <div class="row justify-content-center">
      <div class="col-9">
        <h5 class="mb-3">Informações adicionais</h5>
        <ul class="pb-5">
          <li v-for="(item, ind) in list" :key="ind">
            <span v-html="item" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'InfoComp',

  computed: {
    list() {
      if (this.$route.name === 'area-impugnacao') {
        return [
          'Indicar no campo específico o número da questão, a resposta assinada pelo candidato e a resposta divulgada no gabarito;',
          'Indicar no campo específico a fundamentação da não concordância;',
          'Anexar em arquivo em formato PDF cópia da bibliografia que fundamente o recurso;',
          'O candidato terá que apresentar um recurso para cada questão;',
          '<span class="bold">Atenção! Não será aceito recurso fora dos prazos indicados no Edital</span>'
        ]
      }
      if (this.$route.name === 'area-impugnacao-inscricao') {
        return ['A impugnação ao indeferimento de inscrição deve ser acompanhado do comprovante do fundamento']
      }
      if (this.$route.name === 'area-impugnacao-titulos') {
        return [
          'A fundamentação da impugnação ao Resultado e Classificação Preliminar que se referir a prova de títulos, orienta-se que o candidato certifique na sua área os títulos enviados, fazendo o download dos arquivos e verificando se estão válidos ou corrompidos;',
          'Em se tratando de arquivos corrompidos, deve anexar o arquivo a impugnação para que o mesmo seja verificado.'
        ]
      }
      return [
        `O Candidato é responsável pela exatidão e veracidade das informações prestadas no requerimento de inscrição, 
        arcando com as consequências de eventuais erros e/ou do não preenchimento de quaquer campo daquele campo.`,
        'A inscrição no Processo Seletivo implica, deste logo, o reconhecimento e a tácita aceitação, pelo candidato, das condições estabelecidas.',
        'Não será admitida, sob nenhuma hipótese, complementação documental fora do prazo de inscrição.',
        'Para a investidura do cargo é exigido a idade mínima de 18 anos.',
        '<span class="bold">O boleto referente a taxa de inscrição será enviado automaticamente para seu endereço de e-mail em até 48Hrs após o momento da inscrição. Caso passe esse período e ainda não tenha recebido, entre em contato conosco através do endereço <a href="mailto:suporte@ibcgp.org.br" class="bold link">suporte@ibcgp.org.br</a>.</span>',
        '<span class="bold">A inscrição é efetivada em 3 etapas. Realize todas as etapas até visualizar a mensagem “Inscrição realizada com sucesso“!</span>'
      ]
    }
  }
}
</script>
<style lang="sass">
.bg-f2
  background-color: #f2f2f2 !important
li
  text-align: left
  list-style: decimal
  padding-left: 10px
  line-height: 16px
  margin-bottom: 10px
.bold
  font-weight: bold
.link
  color: #57b846 !important
</style>
