<template>
  <div class="area-titulo p-4">
    <div v-if="false" class="mb-5">
      <form @submit.prevent="enviarArquivo">
        <div class="px-md-5">
          <div class="row justify-content-center bg-light py-3">
            <div class="col-12">
              <h5 class="mb-2">Envie os Títulos</h5>
            </div>
            <div class="col-md-3 mb-3">
              <FieldInput required v-model="arquivo.nome" placeholder="Nome do arquivo" />
            </div>

            <div class="col-md-5 mb-3">
              <FieldInput required v-model="arquivo.descricao" placeholder="Descrição do arquivo" />
            </div>

            <div class="col-md-8 mb-3">
              <FieldFile required accept="application/pdf" key="arquivo" v-model="file" label="Tamanho máximo: 2MB" />
            </div>

            <div class="col-md-5">
              <button type="submit" class="btn btn-primary py-1 font-14">
                <i class="fa-solid fa-plus" />
                Adicionar Título
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <h5 class="mb-2">Lista de Títulos enviados</h5>

    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <p v-else-if="!list.length" class="text-secondary text-center">Nenhum Título enviado</p>

    <div v-else class="px-md-5">
      <div
        :key="item.title"
        v-for="(item, ind) in list"
        :class="['row justify-content-between align-items-center py-2', (ind + 1) % 2 && 'bg-light']"
      >
        <div class="col-md-auto col-8 px-2 px-md-3">
          <p class="font-13">
            <span class="pt-1 bold">{{ $f.mountDate(item.created_at) }}</span>
            <span class="pt-1 px-3"></span>
            {{ item.nome }}
            <span class="px-3"></span>
            {{ item.descricao }}
          </p>
        </div>
        <div class="col-4 col-md-auto text-center px-0 px-md-3">
          <a download target="_blank" :href="item.link" class="btn btn-primary py-1 font-13 text-white">
            <i class="fa-solid fa-download" />
            Download
          </a>
          <!-- <span
            v-if="!isFinished"
            @click="excluir(item)"
            target="_blank"
            :href="item.link"
            class="btn btn-danger py-1 font-13 text-white pointer ml-2"
          >
            <i class="fa-solid fa-trash-can" />
            Excluir
          </span> -->
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-4" v-if="!isFinished && !isLoading && list.length">
      <div class="col-md-5">
        <form @submit.prevent="finalizarEnvioTitulos">
          <button type="submit" class="btn btn-success py-1 font-14">
            <i class="fa-regular fa-circle-check" />
            Finalizar entrega de Títulos
          </button>
        </form>
      </div>
    </div>

    <div v-if="profile.entrega_titulos_finalizada === 'S' && !isLoading">
      <p class="mt-5 text-success weight-400">Títulos entregues.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import FieldFile from '@/components/fields/FieldFile'
import FieldInput from '@/components/fields/FieldInput'

export default {
  components: {
    FieldInput,
    FieldFile
  },

  data() {
    return {
      list: [],
      isLoading: false,
      isFinish: false,
      arquivo: {
        nome: '',
        descricao: ''
      },
      file: {}
    }
  },

  created() {
    this.loadArchives()
  },

  methods: {
    async excluir(item) {
      this.$sweet.sweetWait('Excluindo Título.')
      const { error } = await axios
        .delete(`/formulario/vicentina/vicentinaps1/area-candidato/arquivo/${item.id}`)
        .catch(() => ({ error: 'error' }))
      if (error) return this.$sweet.sweetMensagem('Erro ao remover o Título. Tente novamente')
      this.$sweet.sweetSuccess('Título excluído com sucesso.')
      this.loadArchives()
    },

    async loadArchives() {
      this.isLoading = true
      const { data } = await axios.get('/formulario/vicentina/vicentinaps1/area-candidato/arquivo')
      this.isLoading = false
      this.list = (data || []).map((i) => ({
        ...i,
        link: `https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/vicentina/ps1/titulos/${this.profile.id}/${i.id}.pdf`
      }))
    },

    async finalizarEnvioTitulos() {
      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Finalizar entrega de Títulos',
        '<p class="font-15">Ao efetivar a entrega dos títulos, você não poderá enviar novos Títulos e não poderá excluir os inseridos.</p>'
      )
      if (!value) return

      this.$sweet.sweetWait('Finalizando entrega de Títulos.')
      const { data } = await axios.put('/formulario/vicentina/vicentinaps1/area-candidato/entrega-titulos-finalizada/S')
      localStorage.setItem('token-vicentina', data?.token)
      this.$sweet.sweetSuccess('Entrega de Títulos finalizada.')
      this.$store.dispatch('profile/setProfile', { ...this.profile, entrega_titulos_finalizada: 'S' })
      this.isFinish = true
    },

    async enviarArquivo() {
      this.$sweet.sweetWait('Enviando Título.')

      const { data: idArchive } = await axios.post('/formulario/vicentina/vicentinaps1/area-candidato/arquivo', this.arquivo)
      if (isNaN(idArchive)) return this.$sweet.sweetMensagem('Erro ao criar o Título.')

      const { data: ext } = await this.saveDoc(idArchive)
      if (!ext) return this.$sweet.sweetMensagem('Erro ao enviar o Título.')

      const { data: putExt } = await axios.put(`/formulario/vicentina/vicentinaps1/area-candidato/arquivo/${idArchive}/extensao/${ext}`)
      if (!putExt) return this.$sweet.sweetMensagem('Erro ao atualizar a extensão do Título.')

      this.$sweet.sweetSuccess('Título cadastrado com sucesso.')
      this.loadArchives()
    },

    async saveDoc(id) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('arquivo', this.file)
      dataForm.append('diretorio', `concurso-ps/vicentina/ps1/titulos/${this.profile.id}`)
      dataForm.append('identificador', id)
      return axios.post('/arquivo', dataForm, { headers }).catch((err) => err)
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' }),

    isFinished() {
      return this.profile.entrega_titulos_finalizada === 'S'
    }
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  p
    line-height: 18px
    text-align: left
  a
    margin: auto
    height: 22px
    display: inline-flex
    align-items: center
    justify-content: center
.area-titulo
  background-color: #fff
  padding: 25px 15px
  border-radius: 5px
h5
  font-size: 18px
  text-align: center
  font-weight: 500 !important
.font-13
  font-size: 13px
.font-15
  font-size: 15px
.font-14
  font-size: 14px
.is-loading
  width: 30px
.weight-400
  font-weight: 400
</style>
