<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  // created() {
  //   this.$sweet.sweetMensagem(
  //     `
  //       <p>Os HORÁRIOS E LOCAL DE PROVAS OBJETIVAS do PROCESSO SELETIVO SIMPLIFICADO
  //       para formação de cadastro de reserva para contratação temporária de professores
  //       e outros cargos da rede pública municipal de educação do Município de
  //       Vicentina/MS constam do Edital disponível na área de publicações da página de
  //       concursos e na área do candidato.</p>
  //       <p>As provas serão realizadas no dia 04/02/2024 em dois períodos, matutino e
  //       vespertino (manhã e tarde), VERIFIQUE no EDITAL DE DIVULGAÇÃO DOS
  //       HORÁRIOS E LOCAL, o horário da realização da prova objetiva para o cargo
  //       escolhido.</p>
  //       <p>As provas serão realizadas apenas no horário definido para o cargo
  //       pretendido/escolhido. Em caso de dúvidas entre em contato com o SUPORTE DE
  //       PLANTÃO via Whatsapp através do telefone <a href="https://web.whatsapp.com/send?phone=556730424800" target="_blank">(67) 3042-4800</a></p>
  //     `
  //   )
  // }
}
</script>

<style lang="sass">
.text-end
  text-align: end
.swal2-file, .swal2-input, .swal2-textarea
  box-sizing: border-box
  width: 100%
  transition: border-color .3s,box-shadow .3s
  border: 1px solid #d9d9d9
  &:active
    border: 1px solid #d9d9d9 !important
  &:focus
    border: 1px solid #d9d9d9 !important

.vs--disabled
  background-color: #e9ecef !important
  .vs__dropdown-toggle
    background-color: #e9ecef !important
  .vs__search, .vs__open-indicator
    background-color: #e9ecef !important
#app
  background-color: #f2f2f2 !important
  .pointer
    cursor: pointer
  .vs__clear
    display: none !important
  .vs__dropdown-toggle
    border: 1px solid rgba(25, 25, 100, 0.3)
    height: 33px !important
  .vs__search
    margin-top: 2px !important
  .font-16
    font-size: 16px
a, h1, h2, h3, h4, h5, h6, li, ul, span, p, button, label
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important
@media (max-width: 500px)
  label
    font-size: 13px !important
.swal2-styled
  padding-top: 2px !important
  padding-bottom: 2px !important
.font-16
  font-size: 16px !important
.swal2-content
  p
    line-height: 17px !important
    font-size: 14px !important
    font-weight: 500 !important
    margin-bottom: 10px !important
    a
      color: green !important
</style>
