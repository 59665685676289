<template>
  <div class="area-boleto">
    <h5 class="mb-4">Baixe aqui o seu boleto</h5>

    <p v-if="profile.situacaoBoleto === 'Q'">O boleto para esta inscrição já foi baixado.</p>

    <div v-else class="row justify-content-center">
      <a
        download
        class="btn btn-primary py-1"
        :href="`https://plataforma.ibcgp.org.br/api/arquivo/vicentina/formulario?path=formconcursocamara/${profile.id}&identifeier=boleto&extension=pdf`"
        >Faça download do boleto</a
      >
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ profile: 'profile/getProfile' })
  }
}
</script>

<style lang="sass" scoped>
.area-boleto
  background-color: #fff
  padding: 25px 15px
  border-radius: 5px
  h5
    font-size: 18px
    text-align: center
</style>
