<template>
  <div>
    <div class="d-block">
      <label v-if="label" class="label" v-html="`${label} ${required ? '*' : ''}`"></label>
    </div>

    <div class="d-inline-flex flex-wrap mt-1">
      <div v-for="option in options" :key="`${id}-${option.value}`">
        <div class="pl-4 pr-2">
          <input
            :disabled="isDisabled"
            v-model="field"
            class="form-check-input small"
            type="radio"
            :id="`${id}-${option.value}`"
            :value="option.value"
          />
          <label class="label-option text-nowrap" :for="`${id}-${option.value}`">
            {{ option.label }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    id: {},
    label: {},
    options: {},
    required: {},
    isDisabled: {}
  },

  data() {
    return {
      field: ''
    }
  },

  mounted() {
    this.field = this.options.find((item) => item.value === this.value)?.value
  },

  watch: {
    value: {
      handler(value) {
        if (this.$f.isEqual(value, this.field)) return
        this.field = this.options.find((item) => item.value === value)?.value
      }
    },

    field: {
      handler(field) {
        if (this.$f.isEqual(field, this.value)) return
        this.$emit('input', field)
      }
    }
  }
}
</script>
<style>
.form-check-input {
  width: 18px;
  height: 18px;
}
.label-option {
  padding-top: 3px;
  padding-left: 5px;
}
</style>
