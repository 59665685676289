<template>
  <div>
    <label v-if="upperLabel" class="label font-13" v-html="`${upperLabel} ${required ? '*' : ''}`"> </label>

    <input
      type="file"
      :required="required"
      :id="`${keyInput}-file`"
      :accept="accept"
      class="form-control max-25-px"
      @change="changeFile"
      :disabled="isDisabled"
      :placeholder="placeholder"
    />
    <label v-if="label" class="label size-label text-danger font-11" v-html="`${label}`"> </label>
  </div>
</template>
<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: ''
    },
    keyInput: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    upperLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      maxSize: 1000000 * 2
    }
  },

  methods: {
    isValidateSize(size) {
      if (!this.maxSize) return true
      if (size <= this.maxSize) return true
      this.$sweet.sweetMensagem(
        '<h3 style="font-size: 20px">Atenção!</h3><h6 style="color:#646464; font-size: 15px">Tamanho máximo do arquivo é de <span style="color: #ff4163"><b>2MB</b>!</h6>'
      )
      return false
    },

    changeFile() {
      const input = document.getElementById(`${this.keyInput}-file`)
      const attachment = input.files[0]
      const isValideSize = this.isValidateSize(attachment.size)
      if (!isValideSize) return (input.value = '')

      this.$emit('input', attachment)
    }
  }
}
</script>

<style lang="sass">
.label
  width: 100%
  font-weight: 500 !important
  text-align: left !important
.size-label
  width: 100%
  text-align: right !important
.max-25-px
  max-height: 25px !important
  padding-left: 0px !important
.font-11
  font-size: 11px !important
</style>
