<template>
  <div class="area-titulo">
    <div class="mb-5">
      <form @submit.prevent="enviar">
        <div class="px-md-5">
          <div class="row justify-content-center bg-light py-3">
            <div class="col-9">
              <h5 class="mb-5">Formulário de impugnação em face do edital de deferimento e indeferimento de inscrições</h5>
            </div>

            <div class="col-md-10 mb-3">
              <FieldInput
                required
                type="textarea"
                v-model="arquivo.motivo"
                placeholder="Informe aqui o fundamento do recurso"
                label="Justificativa do candidato - Fundamento do recurso"
              />
            </div>

            <div class="col-md-10 mb-3">
              <FieldFile upperLabel="Anexo" accept="application/pdf" key="arquivo" v-model="file" label="Tamanho máximo: 2MB" />
            </div>

            <div class="col-md-5">
              <button type="submit" class="btn btn-success py-1 font-13">
                <i class="fa-solid fa-plus" />
                Adicionar Impugnação
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <h5 class="py-1">Pedidos de Impugnação</h5>

    <img v-if="isLoading" src="@/assets/images/loading.gif" class="is-loading" />

    <p v-else-if="!list.length" class="text-secondary text-center weight-400">Nenhuma Impugnação enviada</p>

    <div v-else class="px-md-5">
      <div :key="item.title" v-for="(item, ind) in list" :class="['row py-2', (ind + 1) % 2 ? 'bg-light-1' : 'bg-light-2']">
        <div class="col-md-auto">
          <p class="font-13 d-inline-flex text-md-center text-start w-100 text-information">
            <span class="bold label-info pr-2">Enviado em:</span>
            {{ $f.mountDate(item.created_at) }}
          </p>
        </div>

        <div class="col-md-auto">
          <p class="font-13 d-inline-flex text-md-center text-start w-100 text-information">
            <span class="bold label-info pr-2">Questão:</span>
            {{ item.questao }}
          </p>
        </div>

        <div class="col-md-auto">
          <p class="font-13 d-inline-flex text-md-center text-start w-100 text-information">
            <span class="bold label-info pr-2">Resposta do Gabarito:</span>
            {{ item.respostaGabarito }}
          </p>
        </div>

        <div class="col-md-auto">
          <p class="font-13 d-inline-flex text-md-center text-start w-100 text-information">
            <span class="bold label-info pr-2">Resposta do Candidato:</span>
            {{ item.respostaCandidato }}
          </p>
        </div>

        <div class="col-12">
          <p class="font-13 d-inline-flex text-md-center text-start w-100 text-information">
            <span class="bold label-info pr-2">Motivo:</span>
            {{ item.motivo }}
          </p>
        </div>

        <div class="col-12 text-center px-0 px-md-3 my-2">
          <div class="d-inline-flex justify-content-center align-items-center">
            <a
              v-if="item.extensao_anexo"
              download
              target="_blank"
              :href="item.link"
              class="btn mx-1 w-100 btn-primary py-1 font-13 text-white"
            >
              <i class="fa-solid fa-download" />
              Anexo
            </a>

            <span
              v-if="item.status === 'P'"
              @click="excluir(item)"
              target="_blank"
              :href="item.link"
              class="btn w-100 btn-danger py-1 font-13 text-white pointer mx-1"
            >
              <i class="fa-solid fa-trash-can font-11" />
              Excluir
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import FieldFile from '@/components/fields/FieldFile'
import FieldInput from '@/components/fields/FieldInput'
import FieldSelect from '@/components/fields/FieldSelect'

export default {
  components: {
    FieldFile,
    FieldInput,
    FieldSelect
  },

  data() {
    const questions = Object.keys(new Array(21).fill(null)).map((i) => ({ label: i, value: i }))
    questions.shift()
    return {
      list: [],
      questions,
      alternativas: [
        { label: 'A', value: 'A' },
        { label: 'B', value: 'B' },
        { label: 'C', value: 'C' },
        { label: 'D', value: 'D' }
      ],
      isLoading: false,
      arquivo: {
        numeroQuestao: 1,
        respostaGabarito: '',
        respostaCandidato: '',
        motivo: ''
      },
      file: {}
    }
  },

  created() {
    this.loadArchives()
  },

  methods: {
    async excluir(item) {
      const { value } = await this.$sweet.sweetQuestionPersonalizado(
        'Excluir',
        '<p class="font-15">Realmente deseja excluir esta impugnação?</p>',
        'Confirmar'
      )
      if (!value) return

      this.$sweet.sweetWait('Excluindo Impugnação.')
      const { error } = await axios
        .delete(`/formulario/vicentina/vicentinaps1/area-candidato/impugnacao/prova/${item.id}`)
        .catch(() => ({ error: 'error' }))
      if (error) return this.$sweet.sweetMensagem('Erro ao remover o Impugnação. Tente novamente')
      this.$sweet.sweetSuccess('Impugnação excluído com sucesso.')
      this.loadArchives()
    },

    async loadArchives() {
      this.isLoading = true
      const { data } = await axios.get('/formulario/vicentina/vicentinaps1/area-candidato/impugnacao/prova')
      this.isLoading = false

      this.list = (data || []).map((i) => {
        const [respostaGabarito, respostaCandidato, motivo] = i.motivo.split('|')
        return {
          ...i,
          motivo: motivo || respostaGabarito,
          respostaGabarito: !motivo ? '' : respostaGabarito,
          respostaCandidato: !motivo ? '' : respostaCandidato,
          link: `https://ibcgp-plataforma.sfo3.cdn.digitaloceanspaces.com/concurso-ps/vicentina/ps1/pedidos-impugnacao/${this.profile.id}/${i.id}.${i.extensao_anexo}`
        }
      })
    },

    async enviar() {
      if (!this.arquivo.numeroQuestao || !this.arquivo.respostaGabarito || !this.arquivo.respostaCandidato) {
        this.$sweet.sweetMensagem('Por favor, preencha todos os campos obrigatórios.')
        return
      }

      this.$sweet.sweetWait('Enviando Impugnação.')
      const params = {
        numeroQuestao: this.arquivo.numeroQuestao,
        motivo: `${this.arquivo.respostaGabarito} | ${this.arquivo.respostaCandidato} | ${this.arquivo.motivo}`
      }

      const { data: idArchive } = await axios.post('/formulario/vicentina/vicentinaps1/area-candidato/impugnacao/prova', params)
      if (isNaN(idArchive)) return this.$sweet.sweetMensagem('Erro ao criar a Impugnação.')

      if (this.file?.type && this.file?.size) {
        const { data: ext } = await this.saveDoc(idArchive)
        if (!ext) return this.$sweet.sweetMensagem('Erro ao enviar o arquivo de Impugnação.')

        const { data: putExt } = await axios.put(
          `/formulario/vicentina/vicentinaps1/area-candidato/impugnacao/prova/${idArchive}/extensao/${ext}`
        )
        if (!putExt) return this.$sweet.sweetMensagem('Erro ao atualizar a extensão da Impugnação.')
      }

      this.$sweet.sweetSuccess('Impugnação cadastrado com sucesso.')
      this.loadArchives()
    },

    async saveDoc(id) {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const dataForm = new FormData()
      dataForm.append('arquivo', this.file)
      dataForm.append('diretorio', `concurso-ps/vicentina/ps1/pedidos-impugnacao/${this.profile.id}`)
      dataForm.append('identificador', id)
      return axios.post('/arquivo', dataForm, { headers }).catch((err) => err)
    }
  },

  computed: {
    ...mapGetters({ profile: 'profile/getProfile' })
  }
}
</script>

<style lang="sass" scoped>
@media (max-width: 500px)
  p
    line-height: 18px
    text-align: left
  a
    margin: auto
    height: 22px
    display: inline-flex
    align-items: center
    justify-content: center
.area-titulo
  background-color: #fff
  padding: 25px 0px
  border-radius: 5px
h5
  font-size: 20px
  text-align: center
  margin-bottom: 25px !important
  color: #545454 !important
  font-weight: 500 !important
.font-13
  font-size: 13px
.font-15
  font-size: 15px
.font-14
  font-size: 14px
.is-loading
  width: 30px
.weight-400
  font-weight: 400
.text-start
  text-align: left !important
.bg-light-1
  border-bottom: 1px solid #ccc
  background-color: #f2f2f2 !important
.bg-light-2
  border-bottom: 1px solid #ccc
  background-color: #fafafa !important
.label-info
  font-weight: 600 !important
.text-information
  font-weight: 400 !important
</style>
