<template>
  <div id="app">
    <Login :inscricaoAberta="inscricaoAberta" />
    <ValidarInscricao :inscricaoAberta="inscricaoAberta" />
    <Footer />
  </div>
</template>

<script>
import Login from '@/components/Login'
import Footer from '@/components/Footer'
import ValidarInscricao from '@/components/ValidarInscricao'

export default {
  name: 'HomeComp',
  components: {
    Login,
    Footer,
    ValidarInscricao
  },
  created() {
    // this.$sweet.sweetMensagem(
    //   `
    //     <p class="font-14" style="font-weight: 600 !important; margin-bottom: 5px !important">Campo Grande/MS, 16 de fevereiro de 2024.</p>
    //     <p class="font-16" style="font-weight: 600 !important; margin-bottom: 15px !important">Prezado(a) Candidato(a),</p>
    //     <p class="font-13">Em razão da anulação das provas objetivas aplicadas em 04/02/2024 e em atenção aos Editais ns. 03/2024 e 04/2024, o(a) Senhor(a) foi convocado para realização de prova objetiva para o cargo escolhido, que será aplicada no dia 18/02/2024, no mesmo local, observando os horários, conforme abaixo.</p>
    //     <img class="w-100 mb-3" src="${require('@/assets/images/comunicado-1.jpeg')}">
    //     <img class="w-100 mb-3" src="${require('@/assets/images/comunicado-2.jpeg')}">
    //     <img class="w-100 mb-3" src="${require('@/assets/images/comunicado-3.jpeg')}">
    //     <p class="font-13">A não realização da prova objetiva a ser aplicada no dia 18/02/2024, nos horários acima informados, importará em eliminação do Candidato(a) do Processo Seletivo Simplificado.</p>
    //     <p class="font-13">Caso o(a) Senhor(a) queira desistir do processo seletivo, poderá requerer a restituição da taxa de inscrição até as 23hs59min de hoje (16/02/2024) através do formulário de restituição disponível na área do candidato.</p>
    //     <p class="font-13">Em caso dúvidas, nosso suporte estará de plantão através do WhatsApp pelo telefone (67) 3042-4800.</p>
    //     <p>Atenciosamente, IBCGP</p>
    //   `
    // )
  },

  data() {
    return {
      inscricaoAberta: false,
      page: 'login',
      login: { email: '', form: 'login', cpf: '' }
    }
  }
}
</script>
<style lang="sass">
#app
  position: relative
.font-13
  font-size: 13px !important
.font-16
  font-size: 16px !important
</style>
